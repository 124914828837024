
import {
    defineComponent, Ref, ref, onMounted
} from 'vue';
import Notice from '@/util/notice.base';
import HttpRequest from '@/util/axios.config';
import { ChangeWordList } from '@/methods/setText';
import { community } from '@/data';

interface FileItem {
    importResult: boolean;
}
export default defineComponent({
    emits: ['success'],
    setup(props, { emit }) {
        const isShowProcess = ref(false);
        const isShowLoading = ref(false);
        const isShowTable = ref(false);
        const isImporting = ref(true);
        const fileName = ref('');
        const percent = ref('');
        const fileList: Ref<FileItem[]> = ref([]);
        const successList: Ref<FileItem[]> = ref([]);
        const errorList: Ref<FileItem[]> = ref([]);
        const step = ref(0);
        const totalCount = ref(0);
        const errorCount = ref(0);
        let uniqueId = '';

        const ele: Ref<any> = ref();

        const init = () => {
            isShowProcess.value = false;
            isShowLoading.value = false;
            isShowTable.value = false;
            isImporting.value = false;
            fileName.value = '';
            step.value = 0;
            totalCount.value = 0;
            errorCount.value = 0;
        };

        const closeDialog = () => {
            init();
            isImporting.value = true;
        };

        const choose = () => {
            ele.value.click();
        };

        onMounted(() => {
            ele.value.addEventListener('change', () => {
                const { size } = ele.value.files[0];
                if (size > 100 * 1024 * 1024) {
                    ele.value.value = '';
                    Notice.message(WordList.ProperAllTextFileNoMare100M, 'error');
                    return false;
                }

                fileName.value = ele.value.files[0].name;
                if (!/.zip$/.test(fileName.value)) {
                    ele.value.value = '';
                    fileName.value = '';
                    Notice.message(WordList.PropertyAllTextZipFile, 'error');
                }

                return true;
            });
        });

        const refalshTips = (e: Event) => {
            if (isShowProcess.value === true) e.returnValue = true;
        };
        window.addEventListener('beforeunload', refalshTips);

        const getImportPersent = () => {
            isImporting.value = false;
            const url = community.ProjectType.value === 'office' ? `v3/web/${community.ProjectType.value}/face/getImportResult`
                : 'v3/web/community/face/getImportResultForOld';
            HttpRequest.get(url, {
                UniqueId: uniqueId
            }, (data: {data: { percent: string; fileList: Array<FileItem>}}) => {
                percent.value = `${Number(data.data.percent) * 100}%`;
                isShowLoading.value = false;
                isShowTable.value = true;
                fileList.value = data.data.fileList;
                totalCount.value = data.data.fileList.length;

                const errorListTemp: Array<FileItem> = [];
                const successListTemp: Array<FileItem> = [];

                data.data.fileList.forEach((item) => {
                    if (item.importResult) successListTemp.push(item);
                    else errorListTemp.push(item);
                });

                successList.value = successListTemp;
                errorList.value = errorListTemp;
                errorCount.value = errorListTemp.length;
                emit('success');
            }, (error: string) => {
                init();
            });
        };

        const submit = () => {
            const form = new FormData();
            form.append('PhotoFile', ele.value.files[0]);
            ele.value.value = '';
            init();
            HttpRequest.http.defaults.onUploadProgress = (event: any) => {
                if (event.lengthComputable) {
                    const { loaded, total } = event;
                    const complete = ((loaded / total) * 100).toFixed(1);
                    percent.value = `${Math.round(Number(complete))}%`;
                    isShowLoading.value = true;
                }
            };
            const url = community.ProjectType.value === 'office' ? `v3/web/${community.ProjectType.value}/face/import`
                : 'v3/web/community/face/importForOld';

            HttpRequest.post(url, [form, false], [(res: { data: {taskID: string} }) => {
                const { data } = res;
                console.log('data', data);
                uniqueId = data.taskID;
                step.value = 1;
                getImportPersent();
                HttpRequest.http.defaults.onUploadProgress = () => ({});
            }, false]);
        };

        return {
            ele,
            isShowProcess,
            isShowTable,
            isShowLoading,
            isImporting,
            fileName,
            fileList,
            successList,
            errorList,
            errorCount,
            totalCount,
            percent,
            step,
            choose,
            submit,
            closeDialog,
            ChangeWordList
        };
    }
});
